<script>
  /**
   * A dividing line.
   */
  export default {
    props: {
      /** The color of the divider. */
      color: {
        type: String,
        default: 'dawn-dk4',
        validator: (value) =>
          [
            'dawn-dk4',
            'dawn-dk2',
            'dawn',
            'gray-active',
            'black',
            'white',
            'gray-opacity68',
          ].includes(value),
      },
      /** The thickness of the divider, in pixels. */
      weight: {
        type: Number,
        default: 1,
        validator: (value) => [1, 2, 3].includes(value),
      },
      /** The maximum width of the divider. */
      maxWidth: {
        type: String,
        default: 'none',
        validator: (value) => ['none', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value),
      },
    },
    computed: {
      maxWidthClass() {
        switch (this.maxWidth) {
          case 'xs':
            return 'max-w-80'
          case 'sm':
            return 'max-w-120'
          case 'md':
            return 'max-w-180'
          case 'lg':
            return 'max-w-240'
          case 'xl':
            return 'max-w-280'
          case '2xl':
            return 'max-w-320'
          default:
            return ''
        }
      },
    },
  }
</script>

<template>
  <!-- for purgecss: border-t-1 border-t-2 border-t-3 border-dawn-dk2 border-dawn border-gray-active border-black border-white -->
  <hr :class="`border-t-${weight} border-${color} mx-auto ${maxWidthClass}`" />
</template>
